import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, SlotId } from '../../config';
import { EventTypes, GameMode, ISpinAndGrabFeature } from '../../global.d';
import {
  setBottomContainerTotalWin,
  setBrokenGame,
  setCurrentBonus,
  setCurrentSpinAndGrabRound,
  setGrabAndSpinTotalWin,
  setIsContinueAutoSpinsAfterFeature,
  setIsDuringBigWinLoop,
  setIsSlotBusy,
  setIsSpinInProgress,
  setLastRegularWinAmount,
  setReplayBet,
  setReplayFreeSpinBets,
  setSpinAndGrabRoundsLeft,
} from '../../gql/cache';
import SlotMachine from '../../slotMachine';
import { INITIAL_SPIN_AND_GRAB_ROUNDS_NUMBER, PopupTypes, eventManager } from '../../slotMachine/config';
import { PopupController } from '../../slotMachine/popups/PopupController';
import { States } from '../config';
import { Logic } from '../index';

import { BaseController } from './BaseController';

export class SpinAndGrabController extends BaseController {
  public override gameMode: GameMode = GameMode.SPIN_AND_GRAB;

  public static override the = new SpinAndGrabController();

  public currentActiveSpinAndGrabRound = 1;

  private isCollectShouldBeTriggeredBeforeGameFlow = false;

  protected constructor() {
    super();

    const spinAndGrabSpin = () => {
      Logic.the.spin();

      eventManager.emit(
        EventTypes.UPDATE_SPIN_AND_GRAB_COUNTER,
        setSpinAndGrabRoundsLeft(setSpinAndGrabRoundsLeft() - 1),
      );

      AudioApi.play({ type: ISongs.SFX_UI_SpinStart });
    };

    eventManager.on(EventTypes.NEXT_SPIN_AND_GRAB_ROUND, spinAndGrabSpin);
  }

  public override enterIdleState(_prevState: States): void {
    const replayFreeSpins = setReplayFreeSpinBets();
    if (setReplayBet() && !replayFreeSpins.length) return;
    const spinAndGrabFeatureConfig = setCurrentBonus().data.spinAndGrabFeature as ISpinAndGrabFeature;
    const activeSpinAndGrabRound = spinAndGrabFeatureConfig.rounds[this.currentActiveSpinAndGrabRound - 1];

    if (this.isCollectShouldBeTriggeredBeforeGameFlow) {
      const collectorSymbol = (setCurrentBonus().data.spinAndGrabFeature as ISpinAndGrabFeature)?.startingCells.filter(
        (cell) => cell.symbol === SlotId.C,
      )[0];
      this.isCollectShouldBeTriggeredBeforeGameFlow = false;

      eventManager.emit(EventTypes.START_SPIN_AND_GRAB_COLLECT_FEATURE, collectorSymbol);
      eventManager.once(EventTypes.END_SPIN_AND_GRAB_COLLECT_FEATURE, () => {
        setCurrentSpinAndGrabRound(activeSpinAndGrabRound);
        setTimeout(() => eventManager.emit(EventTypes.NEXT_SPIN_AND_GRAB_ROUND), 500);
      });
    } else if (activeSpinAndGrabRound) {
      setCurrentSpinAndGrabRound(activeSpinAndGrabRound);

      setTimeout(() => eventManager.emit(EventTypes.NEXT_SPIN_AND_GRAB_ROUND), 500);
    } else {
      // eventManager.emit(EventTypes.FINISH_SPIN_AND_GRAB);

      eventManager.emit(EventTypes.START_SPIN_AND_GRAB_FINAL_COLLECT_FEATURE);
    }
  }

  public override enterBeforeWinState(_prevState: States): void {
    const isCollectShouldBeTriggered = setCurrentSpinAndGrabRound().newCells.filter(
      (cell) => cell.symbol === SlotId.C,
    ).length;

    if (isCollectShouldBeTriggered) {
      eventManager.emit(EventTypes.START_SPIN_AND_GRAB_COLLECT_FEATURE);

      eventManager.once(EventTypes.END_SPIN_AND_GRAB_COLLECT_FEATURE, () => {
        this.currentActiveSpinAndGrabRound += 1;
        Logic.the.changeState(States.IDLE);
      });
    } else {
      this.currentActiveSpinAndGrabRound += 1;
      Logic.the.changeState(States.IDLE);
    }
  }

  public override enterSpinState(_prevState: States): void {
    SlotMachine.the().spinSpinAnimation();
  }

  public override enterJingleState(_prevState: States): void {}

  public override enterController(_prevGameMode: GameMode): void {
    if (setBrokenGame()) {
      setIsSpinInProgress(true);
      AudioApi.play({ type: ISongs.BGM_SG_Loop });
    }
    setIsSlotBusy(false);

    eventManager.emit(EventTypes.DISABLE_PAYTABLE);
    if (setBottomContainerTotalWin() > 0) {
      eventManager.emit(EventTypes.UPDATE_TOTAL_WIN_VALUE, setBottomContainerTotalWin());
    } else {
      eventManager.emit(EventTypes.HIDE_WIN_LABEL);
    }

    this.currentActiveSpinAndGrabRound = 1;
    this.isCollectShouldBeTriggeredBeforeGameFlow = Boolean(
      (setCurrentBonus().data.spinAndGrabFeature as ISpinAndGrabFeature)?.startingCells.filter(
        (cell) => cell.symbol === SlotId.C,
      ).length,
    );
    eventManager.emit(
      EventTypes.UPDATE_SPIN_AND_GRAB_COUNTER,
      setSpinAndGrabRoundsLeft(INITIAL_SPIN_AND_GRAB_ROUNDS_NUMBER),
    );
    eventManager.emit(EventTypes.ENTER_SPIN_AND_GRAB_MODE);
    if (setIsContinueAutoSpinsAfterFeature()) {
      AudioApi.play({ type: ISongs.BGM_SG_Loop });
      if (setIsDuringBigWinLoop()) {
        AudioApi.fadeOut(0, ISongs.BGM_SG_Loop);
      }
      Logic.the.changeState(States.IDLE);
    } else {
      eventManager.once(EventTypes.START_SPIN_AND_GRAB, () => {
        PopupController.the.closeCurrentPopup();
        Logic.the.changeState(States.IDLE);
      });
      PopupController.the.openPopup(PopupTypes.SPIN_AND_GRAB_START);
    }
  }

  public override exitController(_nextGameMode: GameMode): void {
    setIsSpinInProgress(false);
    setLastRegularWinAmount(setGrabAndSpinTotalWin());
    AudioApi.stop({ type: ISongs.BGM_SG_Loop });
    if (setBrokenGame()) setBrokenGame(false);
    setCurrentBonus({ ...setCurrentBonus(), isActive: false });
  }
}
