import type { RemoteStorage } from '@phoenix7dev/setting-store-client';

import { SlotId } from './config';
import { ReelSetType } from './gql/d';
import { Icon, LineSet } from './slotMachine/d';

export interface IUserBalance {
  id: string;
  clientId: string;
  balance: {
    amount: number;
    currency: string;
  };
}

export interface IAuthInput {
  clientId: string;
  token: string;
  slotId: string;
  lng: string;
  home: string;
}

export interface IGetBonusesInput {
  id: string;
  purchasable: boolean;
}

export interface IBuyBonusesInput {
  id: string;
  coinValue: number;
}

export interface Payline {
  lineId: number | null;
  winPositions: number[];
  amount: number;
}
export interface BonusData {
  spinAndGrabFeature: ISpinAndGrabFeature;
}
export interface ISettledBet {
  bet: {
    id: string;
    coinAmount: number;
    coinValue: number;
    slotId: string;
    result: {
      reelPositions: number[];
      winCoinAmount: number;
      spinResult: Icon[];
    };
    userBonusId: string;
    userBonus: {
      id: string;
      bonusId: string;
      lineSet: LineSet;
    };
    reelSetId: string;
    reelSet: ReelSet;
    lineSet: LineSet;
    data: {
      bonuses: UserBonus[];
      features: {
        gameRoundStore: {
          totalMoneyCollectValue: number;
        };
        moneyCollectFeature: IMoneyBagFeature;
        bonusData: BonusData;
      };
    };
    createdAt: string;
    updatedAt: string;
  };
  paylines: Payline[];
  balance: {
    placed: {
      amount: number;
      currency: string;
    };
    settled: {
      amount: number;
      currency: string;
    };
  };
}

export type IMoneySymbolsData = { position: number; value: number };

export type IMoneyBagFeature = {
  moneySymbols: IMoneySymbolsData[];
  collectSymbols: IMoneySymbolsData[];
};

export type ReelSet = {
  id: string;
  layout: SlotId[][];
  type: ReelSetType;
  additionalReelSets: SlotId[][];
};

export type UserBonus = {
  bonusId: string;
  bonus: {
    type: string;
  };
  id: string;
  isActive: boolean;
  gameMode: GameMode;
  currentRound: number;
  betId: string;
  coinValue: number;
  coinAmount: number;
  rounds: number;
  roundsPlayed: number;
  isFreeBet?: boolean;
  status: BonusStatus;
  data: {
    frbReferenceId: string | null;
    spinAndGrabFeature?: ISpinAndGrabFeature;
  };
  reelSetId?: string;
  totalWinAmount: number;
};

export interface ISpinAndGrabFeature {
  moneyCollectValue: number;
  multiplier: number;
  rounds: ISpinAndGrabRound[];
  startingCells: ISpinAndGrabSlot[];
}

export interface ISpinAndGrabSlot {
  symbol: SlotId.E | SlotId.C | SlotId.M;
  position: number;
  value?: number;
}

export interface ISpinAndGrabRound {
  newCells: ISpinAndGrabSlot[];
  remainingRounds: number;
  roundNumber: number;
}

export enum GameMode {
  BASE_GAME,
  FREE_SPINS,
  BUY_FEATURE,
  SPIN_AND_GRAB,
  FREE_ROUND_BONUS,
}

export type BuyFeatureEnterProps = {
  bonusId: string;
};
export type FreeSpinsEnterProps = {
  bonus: UserBonus;
};
export type EnterProps = (BuyFeatureEnterProps | FreeSpinsEnterProps) & {
  immediate?: boolean;
  skipIdle?: boolean;
  endBonus?: boolean;
};

export const freeSpinsReelSetIds = ['3b3f3a19-8370-4203-a994-cc65141ffe56', 'e3791887-bb01-464e-b022-63e963a18b19'];

export const spinAndGrabReelSetIds = ['8cb49524-51a7-4460-8071-d43d9a2dcedb'];

export enum FeatureState {
  FREE_SPINS,
  SPIN_AND_GRAB,
  FREE_ROUND_BONUS,
}
export const baseReelSetIds = ['6bf1addc-9650-4737-9681-96d1c56f4dfa', 'd045b6ed-3fc2-484a-9ac1-f285efb83b4e'];

export const bonusesId = {
  [GameMode.FREE_SPINS]: '9f915072-031a-4aca-9064-1dbf371f7ee4',
  [GameMode.SPIN_AND_GRAB]: 'ac991a27-61ff-4e3c-8144-7e3255879bef',
  [GameMode.FREE_ROUND_BONUS]: '9ac3796b-dcbc-49f4-b1db-95f4ac53a214',
};
export const buyFeatureBonusesId = {
  [FeatureState.SPIN_AND_GRAB]: '9d8b2a7e-216b-4f3a-8de0-0b6a683a53f4',
  [FeatureState.FREE_SPINS]: 'c4c26a3c-f513-46ab-b972-b525fc6217f8',
};

export interface IBonus {
  id: string;
  type: string;
  coinAmount: number;
  isActive: boolean;
  bonusId: string;
  roundsPlayed: number;
  currentRound: number;
  rounds: number;
  reelSetId: string;
  totalWinAmount: number;
  betId: string;
}
export interface GetUserBonusesInput {
  id: string;
  status: BonusStatus;
}

export type FreeSpinsTitleProps = {
  text: string;
  spins: string;
  currentSpin: string;
};

export type MessageBannerProps = {
  title?: string;
  titlePosition?: number;
  mobileTitlePosition?: number;
  titleStyles?: TextStyle;
  mobileTitleStyle?: TextStyle;
  subtitle?: string;
  subtitlePosition?: number;
  mobileSubtitlePosition?: number;
  subtitleStyles?: TextStyle;
  mobileSubtitleStyle?: TextStyle;
  btnText?: string;
  additionalText?: string;
  additionalPosition?: number;
  additionalStyles?: TextStyle;
  mobileAdditionalStyle?: TextStyle;
  preventDefaultDestroy?: boolean;
  mobileSubtitleBorderWidth?: number;
  mobileSubtitleBorderHeight?: number;
  subtitleBorderWidth?: number;
  subtitleBorderHeight?: number;
  callback?: () => void;
  onInitCallback?: () => void;
};

export type ReplayFreeSpinBets = {
  id: string;
  reelSetId: string;
};

export enum EventTypes {
  BROKEN_GAME = 'brokenGame',
  CHANGE_MODE = 'changeMode',
  CLOSE_ALL_EYES = 'closeAllEyes',
  COUNT_UP_END = 'countUpEnd',
  CLOSE_ALL_MULTIPLIER_EYES = 'closeAllMultiplierEyes',
  DISABLE_ALL_MINI_PAY_TABLES = 'disableAllMiniPayTables',
  DISABLE_PAYTABLE = 'disablePaytable',
  END_TWEEN_ANIMATION = 'endTweenAnimation',
  END_FREE_SPINS = 'endFreeSpins',
  END_COLLECT_FEATURE = 'endCollectFeature',
  SET_COLLECT_VALUE = 'setCollectValue',
  END_SPIN_AND_GRAB_COLLECT_FEATURE = 'endSpinAndGrabCollectFeature',
  SET_SPIN_AND_GRAB_COLLECT_VALUE = 'setSpinAndGrabCollectValue',
  END_SPIN_AND_GRAB_FINAL_COLLECT_FEATURE = 'endSpinAndGrabFinalCollectFeature',
  RESET_MULTIPLIER_ON_FINAL_COLLECT_FEATURE = 'resetMultiplierOnFinalCollectFeature',
  ENABLE_SOUND_LOADER = 'enableSoundLoader',
  ENABLE_PAYTABLE = 'enablePaytable',
  IMMEDIATE_CLOSE_EYES = 'immediateCloseEyes',
  IMMEDIATE_CLOSE_ALL_MULTIPLIER_EYES = 'immediateCloseEyesMultiplierEyes',
  FORCE_STOP_REELS = 'forceStopReels',
  FORCE_RESIZE = 'forceResize',
  GAME_READY = 'gameReady',
  HIDE_COUNT_UP = 'hideCountUp',
  CHANGE_REELS_DATA = 'changeReelsData',
  HIDE_WIN_LABEL = 'hideWinLabel',
  HIDE_WIN_COUNT_UP_MESSAGE = 'hideWinCountUpMessage',
  HANDLE_SKIP_FADE_ANIMATION = 'handleSkipFadeAnimation',
  HANDLE_START_FADE_ANIMATION = 'handleStartFadeAnimation',
  HANDLE_BUY_BONUS = 'handleBuyBonus',
  HANDLE_UPDATE_FREE_SPINS_TITLE = 'handleUpdateFreeSpinsTitle',
  UPDATE_SPIN_AND_GRAB_COUNTER = 'handleUpdateSpinAndGrabCounter',
  HANDLE_CHANGE_RESTRICTION = 'handleChangeRestriction',
  NEXT_FREE_SPINS_ROUND = 'nextFreeSpinsRound',
  FINISH_SPIN_AND_GRAB = 'finishSpinAndGrab',
  NEXT_SPIN_AND_GRAB_ROUND = 'nextSpinAndGrabRound',
  ENTER_SPIN_AND_GRAB_MODE = 'enterSpinAndGrabMode',
  EXIT_SPIN_AND_GRAB_MODE = 'exitSpinAndGrabMode',
  SPACEKEY_CLOSE_MESSAGE_BANNER = 'spaceKeyCloseMessageBanner',
  REELS_STOPPED = 'reelsStopped',
  GRAB_AND_SPIN_REELS_STOPPED = 'grabAndSpinReelsStopped',
  REEL_LANDED = 'reelLanded',
  REEL_LANDED_ANIMATION_PLAYED = 'reelLandedAnimationPlayed',
  SPIN_AND_GRAB_REEL_LANDED = 'spinAndGrabReelLanded',
  RESIZE = 'resize',
  RESIZE_GAME_CONTAINER = 'resizeGameContainer',
  REMOVE_TWEEN_ANIMATION = 'removeTweenAnimation',
  ROLLBACK_REELS = 'rollbackReels',
  SET_SLOTS_VISIBILITY = 'setSlotsVisibility',
  SET_SPIN_AND_GRAB_SLOTS_VISIBILITY = 'setSpinAndGrabSlotsVisibility',
  START_TWEEN_ANIMATION = 'startTweenAnimation',
  START_WIN_ANIMATION = 'startWinAnimation',
  START_SPIN_ANIMATION = 'startSpinAnimation',
  START_SPIN_AND_GRAB_ANIMATION = 'startSpinAndGrabAnimation',
  START_MODE_CHANGE_FADE = 'startModeChangeFade',
  START_BUY_FEATURE_ROUND = 'startBuyFeatureRound',
  START_COUNT_UP = 'startCountUp',
  START_BIG_WIN_PRESENTATION = 'startBigWinPresentation',
  START_FREE_SPINS = 'startFreeSpins',
  START_SPIN_AND_GRAB = 'startSpinAndGrab',
  START_COLLECT_FEATURE = 'startCollectFeature',
  START_SPIN_AND_GRAB_COLLECT_FEATURE = 'startSpinAndGrabCollectFeature',
  START_SPIN_AND_GRAB_FINAL_COLLECT_FEATURE = 'startSpinAndGrabFinalCollectFeature',
  SHOW_PAY_TABLE = 'showPayTable',
  SHOW_WIN_LABEL = 'showWinLabel',
  SOUND_INITIALIZED = 'soundInitialized',
  SET_CURRENT_RESULT_MINI_PAYTABLE = 'setCurrentResultMiniPayTable',
  SKIP_ALL_WIN_ANIMATIONS = 'skipAllWinAnimations',
  SKIP_WIN_COUNT_UP_ANIMATION = 'skipWinCountUpAnimation',
  SKIP_WIN_ANIMATION = 'skipWinAnimation',
  THROW_ERROR = 'throwError',
  UPDATE_BET = 'updateBet',
  UPDATE_USER_BALANCE = 'updateUserBalance',
  UPDATE_TOTAL_WIN_VALUE = 'updateTotalWinValue',
  UPDATE_WIN_VALUE = 'updateWinValue',
  POST_RENDER = 'postrender',
  OPEN_POPUP = 'openPopup',
  OPEN_POPUP_BG = 'openPopupBg',
  CLOSE_POPUP = 'closePopup',
  CLOSE_POPUP_BG = 'closePopupBg',
  DISABLE_BUY_FEATURE_BTN = 'disableBuyFeatureBtn',
  SET_IS_LEFT_HAND_MODE = 'setIsLeftHandMode',
  SET_IS_AUTO_SPINS = 'setIsAutoSpins',
  SET_AUTO_SPINS_LEFT = 'setAutoSpinsLeft',
  SET_IS_SPIN_IN_PROGRESS = 'isSpinInProgress',
  SET_IS_SLOT_IS_BUSY = 'isSlotBusy',
  SET_IS_SLOTS_STOPPED = 'isSlotsStopped',
  SET_IS_FREESPINS_WIN = 'isFreeSpinWin',
  FREE_SPINS_COLLECTOR_ADD_VALUE = 'freeSpinsCollectorAddValue',
  FREE_SPINS_COLLECTOR_UPDATE_VALUE = 'freeSpinsCollectorUpdateValue',
  TOGGLE_SOUND = 'toggleSound',
  TOGGLE_TURBO_SPIN = 'toggleTurboSpin',
  TOGGLE_SPIN = 'toggleSpin',
  HANDLE_DESTROY_INTRO_SCREEN = 'handleDestroyIntroScreen',
  WIN_LINE_ANIMATION_END = 'winLineAnimationEnd',
  SHOW_WIN_LINES = 'showWinLines',
  HIDE_WIN_LINES = 'hideWinLines',
  REMOVE_BAGS_MULTIPLIER = 'removeBagsMultiplier',
  ANTICIPATION_STARTS = 'ANTICIPATION_STARTS',
  SHOW_TINT = 'showTint',
  MOVE_MULTIPLIER_END = 'moveMultiplierEnd',
  MOVE_MULTIPLIER_START = 'moveMultiplierStart',
  MOVE_MULTIPLIER_ANIMATION_START = 'moveMultiplierAnimationStart',
  FORCE_CLOSE_BUYFEATURE = 'forceCloseBuyFeature',
  START_FREE_ROUND_BONUS = 'startFreeRoundBonus',
  END_FREE_ROUND_BONUS = 'endFreeRoundBonus',
  UPDATE_FREE_ROUNDS_LEFT = 'updateFreeRoundsLeft',
  FREE_ROUND_BONUS_EXPIRED = 'freeRoundBonusExpired',
  FORCE_STOP_AUTOPLAY = 'forceStopAutoplay',
  SET_REPLAY_BET_ID = 'setReplayBetId',
  CHANGE_STATE = 'changeState',
}

export enum BonusStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SETTLED = 'SETTLED',
}

export enum FeatureTypes {
  SPECIAL_ROUND = 'SPECIAL_ROUND',
  FREE_SPIN = 'FREE_SPIN',
}
export enum GraphQLErrorsType {
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
}

declare global {
  interface Window {
    __ENV__: {
      ENV: string;
      STATIC_CDN_URL: string;
      STATIC_CDN_DIRECTORY: string;
      APP_VERSION: string;
      APP_NAME: string;
      SENTRY_ENABLED: boolean;
      SENTRY_DSN: string;
      NETWORK_RETRY_ATTEMPTS: number;
      NETWORK_RETRY_DELAY: number;
    };
    __PIXI_APP__: PIXI.Application;
    eventManager: PIXI.utils.EventEmitter;
    remoteStorage: RemoteStorage;
  }
}

export enum ModalOpeningTypes {
  MENU = 'MENU',
  AUTOPLAY = 'AUTOPLAY',
  BET_SETTINGS = 'BET_SETTINGS',
  BUY_FEATURE_POPUP = 'BUY_FEATURE_POPUP',
  NONE = null,
}
