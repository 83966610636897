import { Container, Graphics, isMobile } from 'pixi.js';

import { EventTypes, GameMode } from '../../global.d';
import type { BigWinContainer } from '../bigWinPresentation/bigWinContainer';
import { ViewContainer } from '../components/ViewContainer';
import { FRAME_WIDTH, SLOTS_CONTAINER_HEIGHT, SLOTS_CONTAINER_WIDTH, eventManager } from '../config';
import type { IGameContainer } from '../d';
import Frame from '../frame/frame';
import Logo from '../logo/logo';
import type PaylinesContainer from '../paylines/paylinesContainer';
import type GrabAndSpinReelsBackgroundContainer from '../reels/background/grabAndSpinReelsBackground';
import type GrabAndSpinReelsContainer from '../reels/reelsContainer/grabAndSpinReelsContainer';
import type { ReelsContainer } from '../reels/reelsContainer/reelsContainer';
import type WinCountUpMessage from '../winAnimations/winCountUpMessage';

import { FreeSpinsLeft } from './freeSpinsLeft';
import GameReplay from './gameReplay';
import { SpinAndGrabRoundCounter } from './spinAndGrabRoundCounter';

class GameView extends ViewContainer {
  public paylinesContainer: PaylinesContainer;

  public winSlotsContainer: Container;

  public miniPayTableContainer: Container;

  public reelsBackgroundContainer: Container;

  public reelsContainer: ReelsContainer & ViewContainer;

  public tintContainer: Container;

  public grabAndSpinReelsContainer: GrabAndSpinReelsContainer & ViewContainer;

  public grabAndSpinReelsBackgroundContainer: GrabAndSpinReelsBackgroundContainer;

  public slotsContainer: Container;

  public bigWinContainer: BigWinContainer;

  public winCountUpMessage: WinCountUpMessage;

  public frame: Frame;

  public gameLogo: Logo;

  public gameReplay: Container;

  public freeSpinsLeft: FreeSpinsLeft;

  public spinAndGrabRoundCounter: SpinAndGrabRoundCounter;

  public maskArea: Graphics;

  constructor(props: IGameContainer) {
    super();
    this.sortableChildren = true;
    this.slotsContainer = new Container();
    this.slotsContainer.width = SLOTS_CONTAINER_WIDTH;
    this.slotsContainer.height = SLOTS_CONTAINER_HEIGHT;
    this.slotsContainer.y = 130;
    this.maskArea = new Graphics()
      .beginFill(0xffffff)
      .drawRect(0, 0, SLOTS_CONTAINER_WIDTH, SLOTS_CONTAINER_HEIGHT)
      .endFill();

    this.slotsContainer.interactive = true;
    this.freeSpinsLeft = new FreeSpinsLeft();
    this.spinAndGrabRoundCounter = new SpinAndGrabRoundCounter();
    this.gameLogo = new Logo();
    this.gameReplay = new GameReplay();
    this.winSlotsContainer = props.winSlotsContainer;
    this.winSlotsContainer.y = this.slotsContainer.y;
    this.paylinesContainer = props.paylinesContainer;
    this.tintContainer = props.tintContainer;
    this.miniPayTableContainer = props.miniPayTableContainer;
    this.miniPayTableContainer.x = this.slotsContainer.x;
    this.miniPayTableContainer.y = this.slotsContainer.y;
    this.reelsBackgroundContainer = props.reelsBackgroundContainer;
    this.reelsContainer = props.reelsContainer;
    this.grabAndSpinReelsContainer = props.grabAndSpinReelsContainer;
    this.grabAndSpinReelsBackgroundContainer = props.grabAndSpinReelsBackgroundContainer;
    this.bigWinContainer = props.bigWinContainer;
    this.winCountUpMessage = props.winCountUpMessage;
    this.slotsContainer.addChild(this.reelsBackgroundContainer);
    this.slotsContainer.addChild(this.tintContainer);
    this.slotsContainer.addChild(this.paylinesContainer);
    this.slotsContainer.addChild(this.reelsContainer);
    this.slotsContainer.addChild(this.grabAndSpinReelsContainer);
    this.slotsContainer.addChild(this.grabAndSpinReelsBackgroundContainer);
    this.slotsContainer.addChild(this.maskArea);
    this.slotsContainer.mask = this.maskArea;
    this.addChild(this.slotsContainer);
    this.frame = new Frame();
    this.addChild(this.frame);
    this.addChild(this.miniPayTableContainer);
    this.addChild(this.gameLogo);
    this.addChild(this.gameReplay);
    this.addChild(this.freeSpinsLeft);
    this.addChild(this.spinAndGrabRoundCounter);
    this.addChild(this.winSlotsContainer);
    this.addChild(this.winCountUpMessage);
    this.addChild(this.bigWinContainer);
    eventManager.addListener(EventTypes.RESIZE_GAME_CONTAINER, this.resizeGameContainer.bind(this));
  }

  protected override onModeChange(settings: { mode: GameMode }): void {
    switch (settings.mode) {
      case GameMode.BASE_GAME:
        this.handleBaseModeChanges();
        break;
      case GameMode.FREE_SPINS:
        this.handleFreeSpinsChanges();
        break;
      default:
        this.handleBaseModeChanges();
        break;
    }
  }

  private handleBaseModeChanges(): void {
    // handleBaseModeChanges
  }

  private handleFreeSpinsChanges(): void {
    // handleFreeSpinsChanges
  }

  private resizeGameContainer(width: number, _height: number): void {
    this.scale.set(width / FRAME_WIDTH);
    if (isMobile.any) {
      this.y = 8;
    } else {
      this.y = 0;
    }
  }
}

export default GameView;
